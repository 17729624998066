import React from "react";
import "./index.css"

export function InfoSection() {

    return (<>
        <div className="info-section-wrapper">
            <div className="info-section-container">

                <div className='section-title text-center'>
                    <h2>Our Mission</h2>
                </div>

                <p className="info-paragraph"><strong>Suniss Up</strong> is an exceptional team of professionals focused on helping those individuals, of any age, going through emotional disturbance. Using our expertise on restructured therapeutic activities and social services we create a familiar environment where staff and customers can find a place to be more than a team, a family.</p>

                <div className='section-title text-center'>
                    <h2>Our Vision</h2>
                </div>

                <p className="info-paragraph">We believe with Love and Faith all is possible and people could achieve their goals and live their life in better way in the path to pursue Happiness. We are here just to show the way. When you have a Dream... <strong>Sun is Up beyond the mountains.</strong> </p>
            </div>
        </div>
    </>)
}