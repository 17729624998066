import React from "react";
import imageLogo from "../../images/Logo-vector-transparent-border.png";
import "./index.css"

import imageBg from '../../images/bg/main-bg.jpg'


export function VideoBackGround() {

    return (<>
        <video
            playsInline
            autoPlay
            muted
            loop
            poster={imageBg}
            id="bgvid">

            {/* <source src="polina.webm" type="video/webm"/> */}

            <source
                src="https://sunissup-storage.s3.amazonaws.com/iStock-647732114.mp4"
                // src="https://simuove-online-repo.s3.amazonaws.com/istockphoto-647732114-640_adpp_is.mp4"
                type="video/mp4" />
        </video>

        <div className="viewport-header">
            <img src={imageLogo} alt="Sunissup_logo" />
        </div>
    </>)
}