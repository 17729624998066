import React from 'react';
import { Input } from 'antd';
import './index.css';

const { TextArea } = Input;

export function FieldText(props) {

    const { onChange, value, ...rest } = props

    return (
        <>
            <div className='referrals-form-text-element'>
                <TextArea
                    {...rest}
                    bordered={false}
                    value={value}
                    onChange={onChange}
                    autoSize={{ minRows: 5 }}
                />
            </div>
        </>
    )
}
