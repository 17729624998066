import React, { useState, useEffect } from 'react';
import { ImageBackGround } from '../../components/image-background';
import { FieldGroup } from './components/FieldGroup';
import { FieldInput } from './components/FieldInput';
import { FieldText } from './components/FieldText';
import { SectionTitle } from './components/SectionTitle';
import emailjs from '@emailjs/browser';
import { message as antMessage, Button } from 'antd';

import './index.css';

export function Referrals() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const initialFormState = {
        refAgency: '', refPerson: '', refDate: '', refPhone: '', refFax: '', refEmail: '',
        clientName: '', clientDOB: '', clientSS: '', clientGender: '', clientMedicaid: '', clientMedicare: '',
        clientName2: '', clientDOB2: '', clientSS2: '', clientGender2: '', clientMedicaid2: '', clientMedicare2: '',
        clientLegalGuardian: '', clientRelationship: '', clientContactInformation: '', clientCell: '',
        clientZIP: '', clientState: '', clientCity: '', clientAddress: '', clientLanguage: '',
        refReason: '', refReason2: '',
    }

    const initialCheckboxState = {
        caseManagement: false,
        indTherapy: false,
        famTherapy: false,
        adultPSR: false,
        psychiatrist: false,
        other: false,
    }

    const [loading, setLoading] = useState(false)
    const [formState, setFormState] = useState(initialFormState)
    const [checkboxState, setCheckboxState] = useState(initialCheckboxState)

    const { caseManagement, indTherapy, famTherapy, adultPSR, psychiatrist, other } = checkboxState

    const { refAgency, refPerson, refDate,
        refFax, refPhone, refEmail,
        clientName, clientDOB, clientSS, clientGender, clientMedicaid, clientMedicare,
        clientName2, clientDOB2, clientSS2, clientGender2, clientMedicaid2, clientMedicare2,
        clientLegalGuardian, clientRelationship, clientContactInformation, clientCell,
        clientZIP, clientState, clientCity, clientAddress, clientLanguage,
        refReason, refReason2,
    } = formState


    const handleInputChange = (e, field) => {
        const newValue = e.target.value
        setFormState({ ...formState, [field]: newValue })
    }

    const handleCheckbox = (e, field) => {
        e.preventDefault()
        const newValue = e.target.checked
        setCheckboxState({ ...checkboxState, [field]: newValue })
    }

    const Checkbox = ({ label, value, onChange }) => {
        return (
            <div className='checkbox-wrapper'>
                <input type="checkbox" checked={value} onChange={onChange} />
                <div className='checkbox-label'>{label}</div>
            </div>
        );
    };

    const handleSend = (e) => {
        e.preventDefault()

        if (!loading) {
            setLoading(true)

            const hide = antMessage.loading('Sending Referral..', 0);
            setTimeout(hide, 1000);

            const templateParams = {
                ref_agency: refAgency, ref_person: refPerson, ref_date: refDate,
                ref_fax: refFax, ref_phone: refPhone, ref_email: refEmail,
                client_name: clientName, client_dob: clientDOB, client_ss: clientSS,
                client_gender: clientGender, client_medicaid: clientMedicaid, client_medicare: clientMedicare,
                legal_guardian: clientLegalGuardian, relation: clientRelationship, cont_info: clientContactInformation, client_cell: clientCell,
                zip: clientZIP, state: clientState, city: clientCity, address: clientAddress, lang: clientLanguage,
                ref_reason: refReason,
                services:
                    `${caseManagement ? 'Case Management, ' : ''}
                    ${indTherapy ? 'Individual Therapy, ' : ''}
                    ${famTherapy ? 'Family Therapy, ' : ''}
                    ${adultPSR ? 'Adult & PSR, ' : ''}
                    ${psychiatrist ? 'Psychiatrist, ' : ''}
                    ${other ? 'Other, ' : ''}`,
                add_client_name: clientName2, add_client_dob: clientDOB2, add_client_ss: clientSS2,
                add_client_gender: clientGender2, add_client_medicaid: clientMedicaid2, add_client_medicare: clientMedicare2,
                add_ref_reason: refReason2,
            }

            emailjs.send(
                process.env.REACT_APP_EMAIL_SERVICE_ID,
                process.env.REACT_APP_REFERRAL_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_EMAIL_PUBLIC_KEY,
            )
                .then(() => {
                    antMessage.success('Referral sent successfully', 1);
                    setFormState(initialFormState)
                    setCheckboxState(initialCheckboxState)
                    setLoading(false)
                }).catch(() => {
                    antMessage.error('Referral failed to sent', 1);
                    setLoading(false)
                })
        }

    };

    return (
        <>
            <ImageBackGround />

            <div className='referrals-form-container'>
                <div className='refferal-form-header-container'>
                    <div className='refferal-form-header-left-logo'>
                        <img src='img/sunissup-logo.jpg' alt='sunnisup-logo' className='refferal-img-logo' />
                    </div>
                    <div className='refferal-form-header-right'>
                        <div className='refferal-form-title'>REFERRAL FORM</div>

                        <p>12001 SW 128 CT SUITE 101 MIAMI FL 33186 <br />
                            Phone/Fax: (786)975-7485/(954)860-7166</p>
                    </div>
                </div>

                <div style={{ marginBottom: '25px' }} />

                <SectionTitle title='REFERRAL SOURCE INFORMATION' />
                <FieldGroup>
                    <FieldInput
                        footLabel='Referring Agency'
                        value={refAgency}
                        onChange={(e) => handleInputChange(e, 'refAgency')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Referring Person'
                        value={refPerson}
                        onChange={(e) => handleInputChange(e, 'refPerson')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Date'
                        value={refDate}
                        onChange={(e) => handleInputChange(e, 'refDate')}
                        disabled={loading} />
                </FieldGroup>
                <FieldGroup>
                    <FieldInput
                        footLabel='Cell/Phone'
                        value={refPhone}
                        onChange={(e) => handleInputChange(e, 'refPhone')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Fax'
                        value={refFax}
                        onChange={(e) => handleInputChange(e, 'refFax')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Email'
                        value={refEmail}
                        onChange={(e) => handleInputChange(e, 'refEmail')}
                        disabled={loading} />
                </FieldGroup>

                <div style={{ marginBottom: '25px' }} />

                <SectionTitle title='CLIENT INFORMATION' />
                <FieldGroup>
                    <FieldInput
                        footLabel='Client Name'
                        value={clientName}
                        onChange={(e) => handleInputChange(e, 'clientName')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='DOB'
                        value={clientDOB}
                        onChange={(e) => handleInputChange(e, 'clientDOB')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='SS #'
                        value={clientSS}
                        onChange={(e) => handleInputChange(e, 'clientSS')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Gender'
                        value={clientGender}
                        onChange={(e) => handleInputChange(e, 'clientGender')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Medicaid #'
                        value={clientMedicaid}
                        onChange={(e) => handleInputChange(e, 'clientMedicaid')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Medicare #'
                        value={clientMedicare}
                        onChange={(e) => handleInputChange(e, 'clientMedicare')}
                        disabled={loading} />
                </FieldGroup>

                <FieldGroup>
                    <FieldInput
                        footLabel='Legal Guardian'
                        value={clientLegalGuardian}
                        onChange={(e) => handleInputChange(e, 'clientLegalGuardian')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Relationship'
                        value={clientRelationship}
                        onChange={(e) => handleInputChange(e, 'clientRelationship')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Contact Information'
                        value={clientContactInformation}
                        onChange={(e) => handleInputChange(e, 'clientContactInformation')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Cell'
                        value={clientCell}
                        onChange={(e) => handleInputChange(e, 'clientCell')}
                        disabled={loading} />
                </FieldGroup>

                <FieldGroup>
                    <FieldInput
                        footLabel='Language'
                        value={clientLanguage}
                        onChange={(e) => handleInputChange(e, 'clientLanguage')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Address'
                        value={clientAddress}
                        onChange={(e) => handleInputChange(e, 'clientAddress')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='City'
                        value={clientCity}
                        onChange={(e) => handleInputChange(e, 'clientCity')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='State'
                        value={clientState}
                        onChange={(e) => handleInputChange(e, 'clientState')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='ZIP'
                        value={clientZIP}
                        onChange={(e) => handleInputChange(e, 'clientZIP')}
                        disabled={loading} />
                </FieldGroup>

                <div style={{ marginBottom: '25px' }} />

                <SectionTitle title='REASON FOR REFERRAL' />

                <FieldText
                    value={refReason}
                    onChange={(e) => handleInputChange(e, 'refReason')}
                    disabled={loading} />

                <div style={{ marginBottom: '25px' }} />

                <SectionTitle title='SERVICES NEEDED' />

                <div className='services-list-container'>
                    <div className='services-list-column'>
                        <Checkbox
                            label='Case Management'
                            value={caseManagement}
                            onChange={(e) => handleCheckbox(e, 'caseManagement')}
                            disabled={loading} />
                        <Checkbox
                            label='Individual Therapy'
                            value={indTherapy}
                            onChange={(e) => handleCheckbox(e, 'indTherapy')}
                            disabled={loading} />
                        <Checkbox
                            label='Family Therapy'
                            value={famTherapy}
                            onChange={(e) => handleCheckbox(e, 'famTherapy')}
                            disabled={loading} />
                    </div>

                    <div className='services-list-column'>
                        <Checkbox
                            label={'Adult & PSR'}
                            value={adultPSR}
                            onChange={(e) => handleCheckbox(e, 'adultPSR')}
                            disabled={loading} />
                        <Checkbox
                            label='Psychiatrist'
                            value={psychiatrist}
                            onChange={(e) => handleCheckbox(e, 'psychiatrist')}
                            disabled={loading} />
                        <Checkbox
                            label='Other'
                            value={other}
                            onChange={(e) => handleCheckbox(e, 'other')}
                            disabled={loading} />
                    </div>
                </div>

                <div style={{ marginBottom: '25px' }} />

                <SectionTitle title='IF YOU WANT TO REFER ANOTHER HOUSHOLD MEMBER, PLEASE COMPLETE BELOW' />

                <FieldGroup>
                    <FieldInput
                        footLabel='Client Name'
                        value={clientName2}
                        onChange={(e) => handleInputChange(e, 'clientName2')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='DOB'
                        value={clientDOB2}
                        onChange={(e) => handleInputChange(e, 'clientDOB2')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='SS #'
                        value={clientSS2}
                        onChange={(e) => handleInputChange(e, 'clientSS2')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Gender'
                        value={clientGender2}
                        onChange={(e) => handleInputChange(e, 'clientGender2')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Medicaid #'
                        value={clientMedicaid2}
                        onChange={(e) => handleInputChange(e, 'clientMedicaid2')}
                        disabled={loading} />

                    <FieldInput
                        footLabel='Medicare #'
                        value={clientMedicare2}
                        onChange={(e) => handleInputChange(e, 'clientMedicare2')}
                        disabled={loading} />
                </FieldGroup>

                <div style={{ marginBottom: '25px' }} />

                <SectionTitle title='REASON FOR REFERRAL' />

                <FieldText
                    value={refReason2}
                    onChange={(e) => handleInputChange(e, 'refReason2')}
                    disabled={loading} />

                <div className='referral-btn-send-container'>
                    <Button
                        type="primary"
                        shape="round"
                        className='referral-send-btn'
                        onClick={handleSend}
                        disabled={loading}>

                        Send
                    </Button>
                </div>

            </div>

            <div style={{ height: '50px' }} />
        </>
    );
};