import "./index.css"


export const Team = () => {
  return (
    <div id='team'>

      <div className='section-title text-center'>
        <h2>Meet Our Staff</h2>
      </div>

      <div className='row' style={{display: "flex", justifyContent: "center"}}>

        <div className='col-md-3 col-sm-6 team'>
          <div className='thumbnail'>
            <img src="img/team/05.jpg" alt="img" className='team-img' />
            <div className='caption team-caption-box'>
              <h4>{'Esel Aguilar'}</h4>
              <p>{
                ` CEO and Co-founder of the company. He is a Foreign Medical Doctor from Cuba and Certified as a Behavioral Health Case Manager Supervisor with more than 12 years of experience dedicated to Mental Health field since arrived at USA in 2007. His passion is to help others to believe in themselves. `
              }</p>
            </div>
          </div>
        </div>

        <div className='col-md-3 col-sm-6 team'>
          <div className='thumbnail'>
            {' '}
            <img src="img/team/07.jpg" alt="img" className='team-img' />
            <div className='caption team-caption-box'>
              <h4>{'Reynol Valencia'}</h4>
              <p>{
                `CFO and Co-founder of the company. He is specialist in Accounting from Cuba and here in USA with more than 7 years of experience working in financing and Human Resources on a Mental Health Field. He is an example of overcoming and passioned not only for numbers and math but also for serve people. `
              }</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
};
