import React from 'react';
import './index.css';

export function SectionTitle(props) {

    const { title } = props

    return (
        <>
            <div className='referrals-form-section-title'>
                {title}
            </div>
        </>
    )
}
