import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { ImageBackGround } from '../../components/image-background';
import { Button, Input } from 'antd';
import { HiDownload } from 'react-icons/hi';

import './index.css';

export function Forms() {

    const [showLogin, setShowLogin] = useState(true);
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const handlePassword = (e) => {
        setPwd(e.target.value)
        setErrMsg('')
    }

    const handleLogin = (e) => {
        e.preventDefault();
        const masterPass = process.env.REACT_APP_FORMS_PASSWORD
        const authorized = masterPass === pwd ? true : false;
        const error = authorized ? '' : 'Incorrect password'
        setErrMsg(error)
        setShowLogin(!authorized)
    }


return (
    <>
        <ImageBackGround />

        {!showLogin && (
            <>
            <div className='form-page-container download-wrapper'>

                <div className='docs-list-container'>

                    <div className='docs-list-title'>TCM documents</div>
                    <div className='docs-list-wrapper'>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/1-ASSESSMENT.pdf" target="_blank" download>Assessment</Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/2-Service Plan.pdf" target="_blank" download>Service Plan</Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/3-CONSENT.pdf" target="_blank" download>Consent</Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/4-6 MONTH CERTIFICATION.pdf" target="_blank" download>6 Month Certification</Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/5-REFERRAL.pdf" target="_blank" download>Referral</Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/6-INTAKE.pdf" target="_blank" download>Intake</Link>
                        </div>
                    </div>

                    <div className='docs-list-title'>CBHS documents</div>
                    <div className='docs-list-wrapper'>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/BIO.pdf" target="_blank" download>Bio-Psychosocial Assessment </Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/Brief.pdf" target="_blank" download>Brief Behavioral Health Status Examination </Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/CFARS-FARS.pdf" target="_blank" download>CFARS/FARS Evaluations </Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/DISCHARGE_SUMMARY.pdf" target="_blank" download>Discharge Summary</Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/INTAKE.pdf" target="_blank" download>Intake</Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/IT_Note.pdf" target="_blank" download>Individual Therapy Progress Note </Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/IITP.pdf" target="_blank" download>Individualized Initial Treatment Plan </Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/PROGRESS_NOTE.pdf" target="_blank" download>Services Progress Note </Link>
                        </div>
                        <div className='docs-list-element'>
                            <div className='docs-list-icon'><HiDownload /></div>
                            <Link to="/docs/WEEKLY PROGRESS NOTE.pdf" target="_blank" download>Weekly Progress Note </Link>
                        </div>
                    </div>

                </div>
                </div>
            </>
        )}

        {showLogin && (
            <div className='form-page-container login-wrapper'>

            <div className='login-container'>
                <h1>Log in</h1>
                <div className='form-login-container'>
                    <label htmlFor="password">Password:</label>
                    <Input
                        type="password"
                        onChange={handlePassword}
                        value={pwd}
                        onPressEnter={handleLogin}/>
                    <div className="form-login-error">{errMsg}</div>
                    <Button
                        type="primary"
                        shape="round"
                        onClick={handleLogin}>
                        Log in
                    </Button>
                </div>
            </div>
        </div>
        )}
    </>
);
};