import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from './pages/home'
import { Referrals } from './pages/referrals'
import { Insurance } from './pages/insurance'
import { Therapy } from './pages/therapy'
import { Tcm } from './pages/tcm'
import { Forms } from './pages/forms'
import { Careers } from './pages/careers'
import { Navbar } from "./components/navbars/navbar-3";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react'
import "./App.css";
import "./styles/customantd.css"

const App = () => {
  const propertyId = process.env.REACT_APP_CHAT_PROPERTY_ID
  const tawkId = process.env.REACT_APP_CHAT_TAWK_ID

  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/referrals" element={<Referrals />} />
          <Route path="/forms" element={<Forms />} />
          <Route path="/therapy" element={<Therapy />} />
          <Route path="/tcm" element={<Tcm />} />
        </Routes>
      </Router>
      <TawkMessengerReact
        propertyId={propertyId}
        widgetId={tawkId}
        onLoad={() => console.log('LiveChat Loaded')} />
    </>
  );
};
export default App;
