import React from "react";
import "./index.css"

const data = {
  paragraph:
    `Suniss Up is an approved Medicaid and Medicare Mental
  Health Service Provider focused on Behavioral Health
  problems. We provide Targeted Case Management and
  Therapeutic Services like Psychosocial Rehab, Individual
  and Group Therapies and more to help those individuals
  struggling with issues related to trauma, grief, addiction,
  anxiety, or major life changes that can put their life in
  risk of hospitalization or use intensive level of care when
  facing difficulties to deal with daily living process.
  Suniss Up actually can provide services for target
  population located on Miami Dade and Broward Counties
  to support our people on the journey toward healing and
  self-discovery.
  Life can be overwhelming and sometimes even
  exhausting, but Suniss Up is here to guide persons and
  families through any obstacles they may face. For the
  company and team, the main goal is to help others to
  understand themself more deeply and better cope with
  life changes. Since we opened the doors in September
  2020 this goal has been one of our reasons to exist.

  "SUNISS UP when you have a dream beyond the
  mountains"
  
  is the meaning of our name, slogan, and logo.
  We believe that the Sun is Up if you are strong enough to
  believe every day that tomorrow could be a better day.`
}

export const About = () => {
  return (
    <div id='about' >
      <div style={{ height: '80px' }} />
      <div className="about-section-wrapper">
        <div className="about-section-content">

          <div className='section-title text-center'>
            <h2>About Us</h2>
          </div>

          <p className="about-paragraph">{data.paragraph}</p>
        </div>
      </div>
    </div>
  );
};
