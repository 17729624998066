import React from 'react';
import { Link } from "react-router-dom";
import { Button } from 'antd';

import imageTherapy from '../../images/therapy-page.jpg'
import imageTCM from '../../images/TCM-page.jpg'


import "./index.css"

export const Services = () => {
    return (
        <div id='services'>

            <div className='section-title text-center'>
                <h2>Our Services</h2>
            </div>

            <div className='services-cards-container'>

                <div className='service-card'>
                    <div className='service-card-title'>
                        <Link to="/therapy">Group/Individual Therapies/PSR</Link>
                    </div>
                    <Link to="/therapy">
                        <img src={imageTherapy} alt="therapy" width={'100%'} />
                    </Link>
                    <br/>
                    <Link to="/therapy">
                        <Button
                            type="primary"
                            shape="round"
                            className='contact-submit-btn'>
                            More Info
                        </Button>
                    </Link>
                </div>

                <div className='service-card'>
                    <div className='service-card-title'>
                        <Link to="/tcm">Targeted Case Management</Link>
                    </div>
                    <Link to="/tcm">
                        <img src={imageTCM} alt="TCM" width={'100%'} />
                    </Link>
                    <br/>
                    <Link to="/tcm">
                        <Button
                            type="primary"
                            shape="round"
                            className='contact-submit-btn'>
                            More Info
                        </Button>
                    </Link>

                </div>


            </div>

        </div>
    )
}
