import React, { useEffect, useState } from 'react';
import { ImageBackGround } from '../../components/image-background';
import { Link } from "react-router-dom";
import { HiDownload } from 'react-icons/hi';
import emailjs from '@emailjs/browser';
import { Input, Button, message as antMessage } from 'antd';

import './index.css';

const { TextArea } = Input;


export function Careers() {
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const initialState = {
        type: 'WORK APPLICATION',
        name: '',
        email: '',
        message: '',
        phone: '',
      }
    
      const [state, setState] = useState(initialState)
      const [loading, setLoading] = useState(false)
    
      const { name, email, message, phone, type } = state
    
      const handleChange = (e) => {
        const { name, value } = e.target
        setState((prevState) => ({ ...prevState, [name]: value }))
      };
    
    
      const handleSubmit = (e) => {
        e.preventDefault()
    
        if (!loading) {
          setLoading(true)
    
          const hide = antMessage.loading('Sending Message..', 0);
          setTimeout(hide, 1000);
    
          const templateParams = { name, email, message, phone, type }
    
          emailjs.send(
            process.env.REACT_APP_EMAIL_SERVICE_ID,
            process.env.REACT_APP_CONTACT_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_EMAIL_PUBLIC_KEY,
          )
            .then(() => {
              antMessage.success('Request sent successfully', 1);
              setState(initialState)
              setLoading(false)
            })
            .catch(() => {
              antMessage.error('Request failed', 1);
              setLoading(false)
            }
            )
        }
      };
    

    return (
        <>
        <ImageBackGround />
        
        <div className='careers-page-container'>

        <img src='img/sunissup-logo.png' alt='sunnisup-logo' className='creers-img-logo' />
        <br/>

        <div className='careers-page-title'>Careers</div>

        <div className='careers-application-info'>{`Here at 'Suniss Up' we always welcome new members in our family,
                please download the `} 
                <Link className='download-inline' to="/docs/Application for employment.pdf" target="_blank" download>Application for employment from</Link>
                {`, fill out all the fields, and send it to: `}<strong>sunissupbm@gmail.com</strong>
                {`. We will review your information and contact you in no time.`}</div>
        <br/>
        <br/>

        <div className='docs-list-element'>
            <div className='docs-list-icon'><HiDownload /></div>
            <Link to="/docs/Application for employment.pdf" target="_blank" download>Download application form</Link>
        </div>

        <br/>
        <br/>

        <div className='careers-application-info'>{`You can also send us a message in the form bellow,
                please provide your contact information and the  position you are interested in.`}</div>
                <br/>


        <form >
          <div className='section-careers-fields'>

            <Input
              type='text'
              id='name'
              name='name'
              placeholder='Name'
              required
              onChange={handleChange}
              value={name}
              disabled={loading} />

            <br />

            <Input
              type='phone'
              id='phone'
              name='phone'
              placeholder='Phone'
              required
              onChange={handleChange}
              value={phone}
              disabled={loading} />

            <br />

            <Input
              type='email'
              id='email'
              name='email'
              placeholder='Email'
              required
              onChange={handleChange}
              value={email}
              disabled={loading} />

            <br />

            <TextArea
              name='message'
              id='message'
              rows='4'
              placeholder='Position of interest and additional personal information'
              required
              onChange={handleChange}
              value={message}
              disabled={loading} />

            <br />

            <Button
              type="primary"
              shape="round"
              className='contact-submit-btn'
              onClick={handleSubmit}
              disabled={loading}>
              Send Message
            </Button>

          </div>
        </form >
        </div>
        <div className='careers-footer'/>
        </>
    );
};