
import "./index.css"

const data = [
  {
    "img": "img/testimonials/09.jpg",
    "text":
      ` That’s is a great team, they made me feel in family and happy. Case Manager provided excellent services helping me to be more independent and therapies in group helped me to be more functional and having more abilities to deal in group with others losing my fears to socialize. `,
    "name": "Jeremy"
  },
  {
    "img": "img/testimonials/07.jpg",
    "text":
      ` I want let you know that I have a case manager that has been very helpful for me and her services has helped me to improve my quality of life, I recommend a case manager like her from Suniss Up company, very nice services received. `,
    "name": "Elena"
  },
  {
    "img": "img/testimonials/10.jpg",
    "text":
      ` I have been treated by a therapist in an excellent way, therapeutic services are the best quality and helping me to improve my ability to manage my mood. Company has an excellent team that I recommend, they are the best `,
    "name": "Rolando"
  },
  {
    "img": "img/testimonials/08.jpg",
    "text":
      ` Since I started receiving services from therapist and case manager my life has changed, I can say I’m another person. Feeling better, I will not hesitate to be treated by them, if necessary, again. Today I feel better thanks their job. `,
    "name": "Maria Elena"
  }
]

export const Testimonials = () => {
  return (
    <div id='testimonials'>

      <div className='section-title text-center'>
        <h2>What our clients say</h2>
      </div>

      <div className='row'>

        {data.map((d, i) => (
          <div key={`${d.name}-${i}`} className='col-md-3'>
            <div className='testimonial'>
              <div className='testimonial-image'>
                {' '}
                <img src={d.img} alt='' />{' '}
              </div>
              <div className='testimonial-content'>
                <p>“{d.text}”</p>
                <div className='testimonial-meta'> - {d.name} </div>
              </div>
            </div>
          </div>
        ))
        }
      </div>

    </div>
  )
}
