import { useState } from 'react'
import { FaPhoneAlt, FaFax, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';
import emailjs from '@emailjs/browser';
import { Input, Button, message as antMessage } from 'antd';

import "./index.css"

const { TextArea } = Input;

const data = {
  address: ` 12001 sw 128 ct, Suite 101. Miami FL 33186`,
  phone: ` (786) 975-7485`,
  fax: ` (954) 860-7166`,
  email: ` sunissupbm@gmail.com`,
}

export const Contact = () =>{

  const initialState = {
    type: 'CONTACT',
    name: '',
    email: '',
    message: '',
    phone: '',
  }

  const [state, setState] = useState(initialState)
  const [loading, setLoading] = useState(false)

  const { name, email, message, phone, type } = state

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  };


  const handleSubmit = (e) => {
    e.preventDefault()

    if (!loading) {
      setLoading(true)

      const hide = antMessage.loading('Sending Message..', 0);
      setTimeout(hide, 1000);

      const templateParams = { name, email, message, phone, type }

      emailjs.send(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_CONTACT_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY,
      )
        .then(() => {
          antMessage.success('Message sent successfully', 1);
          setState(initialState)
          setLoading(false)
        })
        .catch(() => {
          antMessage.error('Message failed to sent', 1);
          setLoading(false)
        })
    }
  };

  const handleYoutube = () => {
    window.open('http://www.youtube.com', '_blank');
  };
  const handleTwitter = () => {
    window.open('http://www.twitter.com', '_blank');
  };
  const handleFacebook = () => {
    window.open('http://www.facebook.com', '_blank');
  };



  return (
    <div id='contact'>

      <div className='contact-box-container'>
        <div className='section-title text-center'>
          <h2>Get In Touch</h2>
        </div>

        <p>
          Please fill out the form below to send us an email and we will
          get back to you as soon as possible.
        </p>

        <form >
          <div className='section-contact-fields'>

            <Input
              type='text'
              id='name'
              name='name'
              placeholder='Name'
              required
              onChange={handleChange}
              value={name}
              disabled={loading} />

            <br />

            <Input
              type='email'
              id='email'
              name='email'
              placeholder='Email'
              required
              onChange={handleChange}
              value={email}
              disabled={loading} />

            <br />

            <TextArea
              name='message'
              id='message'
              rows='4'
              placeholder='Message'
              required
              onChange={handleChange}
              value={message}
              disabled={loading} />

            <br />

            <Button
              type="primary"
              shape="round"
              className='contact-submit-btn'
              onClick={handleSubmit}
              disabled={loading}>
              Send Message
            </Button>

          </div>

        </form >


        <div className='contact-items-wrapper'>

          <div className='contact-item'>
            <FaPhoneAlt />&nbsp;{data.phone}
          </div>

          <div className='contact-item'>
            <FaFax />&nbsp;{data.fax}
          </div>

          <div className='contact-item'>
            <FaEnvelope />&nbsp;{data.email}
          </div>

          <div className='contact-item'>
            <FaMapMarkerAlt />&nbsp;{data.address}
          </div>

        </div>


        <div className=''>
          <div className='social'>
            <ul>
              <li>
                <div onClick={handleFacebook}>
                  <i className='fa fa-facebook'></i>
                </div>
              </li>
              <li>
                <div onClick={handleTwitter}>
                  <i className='fa fa-twitter'></i>
                </div>
              </li>
              <li>
                <div onClick={handleYoutube}>
                  <i className='fa fa-youtube'></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div >
  )
}
