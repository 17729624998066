import React from 'react';
import { Input } from 'antd';
import './index.css';

export function FieldInput(props) {

    const { footLabel, onChange, value, ...rest } = props

    return (
        <>
            <div className='referrals-form-input-element'>
                <Input
                    {...rest}
                    bordered={false}
                    size="large"
                    value={value}
                    onChange={onChange}
                />
                <div className='referrals-form-row-element-title'>
                    {footLabel}
                </div>
            </div>
        </>
    )
}
