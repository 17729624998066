import React from 'react';
import { ImageBackGround } from '../../components/image-background';

import imageMedicaid from '../../images/Medecaid-logo.png'
import imageMedicare from '../../images/Medicare-Logo.png'


import './index.css';

export function Insurance() {
    return (
        <>
            <ImageBackGround />
            <div className='insurance-page-container'>
                <div className='insurance-header-container'>
                    <h1>INSURANCE</h1>
                    <p className='insurance-paragraph-header'>
                        Suniss Up is base contracted with Florida Medicaid and Medicare. Also contracted with some Manage Care Organizations.
                    </p>
                </div>

                <div className='insurance-type-header'>
                    <img src={imageMedicaid} alt="Sunissup_logo" width={250} />
                    From Medicaid:
                </div>
                <div className='insurance-list-options-container'>
                    <div className='insurance-list-options'>
                        <ul className='insurance-list'>
                            <li>Aetna Better Health </li>
                            <li>Molina</li>
                            <li>Simply Health</li>
                            <li>Humana Health</li>
                            <li>Florida Community Care</li>
                            <li>Sunshine Health </li>
                        </ul>
                    </div>
                </div>


                <div className='insurance-type-header'>
                    <img src={imageMedicare} alt="Sunissup_logo" width={250} />
                    From Medicare:
                </div>
                <div className='insurance-list-options-container'>
                    <div className='insurance-list-options'>
                        <ul className='insurance-list'>
                            <li>Aetna Health Plan </li>
                            <li>Cigna </li>
                            <li>Wellcare Health Plan </li>
                            <li>Devoted Health Plan </li>
                            <li>Simply Health</li>
                            <li>Humana Health</li>
                            {/* <li>DrHealth Health Plan </li> */}
                            {/* <li>HealthSpring Plan </li> */}
                            <li>CarePlus Health Plan </li>
                            <li>Florida Community Care </li>
                        </ul>
                    </div>
                </div>

            </div>
        </>
    );
};