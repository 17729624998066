import React from "react";

import imageBg from '../../images/bg/mountain.png'

export function ImageBackGround() {

    const myStyle = {
        backgroundImage: `url(${imageBg})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        height: '100vh',
        width: '100vw',
        position: 'fixed',
        top: '0',
        left: '0',
        zIndex: '-1',
    }

    return (
        <>
            <div
                style={myStyle}
            />
        </>
    )
}