import React, { useEffect } from 'react';
import { BsDot } from 'react-icons/bs';
import { ImageBackGround } from '../../components/image-background';
import imageTCM from '../../images/TCM-page.jpg'

import './index.css';

export function Tcm() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ImageBackGround />
            <div className='tcm-page-container'>
                <img src={imageTCM} alt="TCM" width={'100%'} />
                <div className='tcm-header-container'>
                    <h1>TCM SERVICES</h1>
                </div>
                <div className='tcm-sub-header-container'>
                   Targeted Case Manager (TCM) will coordinate:
                </div>
                <br/>
              
                <div className='tcm-page-content'>

                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            Medical Services: PCP /Specialist /Dental /Gym Programs.
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            DCF Benefits: Food Stamps and Medicaid Applications.
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            Social Security Benefits: SSI /SSA /Medicare /Disability.
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            Insurance Services: Medicare /Medicaid Applications.
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            Long Term Care Application and follow up.
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                             Housing programs Application: Section-8 /Low Income Programs.
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            Utility Services: LIHEAP /Free Phone /Internet /Cable.
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            Legal Services: Naturalization Process /Passport Application /Green Card renewal /IDs /Divorce /Child Support /Other...                    
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            Donations: Food /Clothes /Furniture /Hygiene Products /Household products /Diapers /Other...
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            Adult /Child Daycare if needed.
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            Transportation Services: STS /Golden Passport /Disabled Parking Permit (DPP) /Providers Transportations.
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            Medical Equipment if required.
                        </div>
                    </div>
                    <div className='service-page-text-tcm-dot'>
                        <div className='service-page-list-bullet'><BsDot size={30}/></div>
                        <div className='tcm-page-list-content'>
                            And more...
                        </div>
                    </div>

                    <br/>
                    <br/>
                    <br/>
                </div>

            </div>
        </>
    );
};