import { About } from "../../components/about";
import { Testimonials } from "../../components/testimonials";
import { Team } from "../../components/team";
import { Services } from "../../components/services";
import { Contact } from "../../components/contact";
import { InfoSection } from "../../components/info-section";
import { SectionSpace } from "../../components/section-space";
import { SectionSpaceFooter } from "../../components/section-space-footer";
import { VideoBackGround } from "../../components/video-background";

import SmoothScroll from "smooth-scroll";

import "../../components/ant-components/Styles/customantd.css"

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

export const Home = () => {


    return (
        <>
            <VideoBackGround />
            <About />
            <SectionSpace />
            <InfoSection />
            <Testimonials />
            <SectionSpace />
            <Team />
            <Services />
            <Contact />
            <SectionSpaceFooter />
        </>
    );
};