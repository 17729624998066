import React from 'react';
import './index.css';

export function FieldGroup(props) {

    const { children } = props

    return (
        <>
            <div className='referrals-form-row'>
                {children}
            </div>
        </>
    )
}
