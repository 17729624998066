import React, { useEffect } from 'react';
import { BsDot } from 'react-icons/bs';
import { ImageBackGround } from '../../components/image-background';
import imageTherapy from '../../images/therapy-page.jpg'

import './index.css';



export function Therapy() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <ImageBackGround />
            <div className='therapy-page-container'>
                <img src={imageTherapy} alt="therapy" width={'100%'} />
                <div className='therapy-header-container'>
                    <h1>Community Behavioral Health Services </h1>
                </div>

                <div className='therapy-page-content'>

                    <div className='service-page-text-dot'>
                    <div className='service-page-list-bullet'><BsDot size={35}/></div>
                    <div className='therapy-text-dot-content'>
                        Psychosocial Rehabilitation Services (PSR)
                    </div>
                    </div>
                    <div className='service-page-text'>
                        Up to 4 hours per day/5 days a week according to client needs.
                    </div>
                    <br/>
                    <div className='service-page-text-dot'>
                    <div className='service-page-list-bullet'><BsDot size={35}/></div>
                    <div className='therapy-text-dot-content'>
                        Individual and Family Therapy
                    </div>
                    </div>
                    <div className='service-page-text'>
                        1 Hour per Week
                    </div>
                    <br/>
                    <div className='service-page-text-dot'>
                    <div className='service-page-list-bullet'><BsDot size={35}/></div>
                    <div className='therapy-text-dot-content'>
                        Group Therapy
                    </div>
                    </div>
                    <div className='service-page-text'>
                        up to 2 hours per week
                    </div>
                    <br/>
                    <p> 
                        (Services frequency and duration will be recommended according to  Psychosocial Evaluation and client criteria need.) 
                    </p>
                    
                    <br/>

                    <div className='service-page-text-dot'>
                    <div className='service-page-list-bullet'><BsDot size={35}/></div>
                    <div className='therapy-text-dot-content'>
                        Other services
                    </div>
                    </div>
                    <div className='service-page-text'>
                        -Biopsychosocial Evaluation Development
                    </div>
                    <div className='service-page-text'>
                        -Psychiatric Evaluation Referral/Coordination
                    </div>
                    <div className='service-page-text'>
                       -Medical referral according to client’s needs
                    </div>
                    <br/>
                    <p> 
                        (Some services could be provided via Telehealth with HIPPA compliance )                    
                    </p>
                    <br/>
                </div>


            </div>
        </>
    );
};